<template>
  <div class="container">
    <top-nav :banner="globalData.index.banner" :imgList="banner" white isParent>
      <div slot-scope="index">
        <span v-if="index.index == 6" class="banner-item-sp" style="font-size: 72px">
          {{ globalData.index.txt1 }}
          <span class="banner-item-sp">{{ globalData.index.sp1 }}</span>
        </span>
        <span v-if="index.index == 7" class="banner-item-sp" style="font-size: 72px">
          {{ globalData.index.txt2 }}
          <span class="banner-item-sp">{{ globalData.index.sp2 }}</span>
        </span>
        <span v-if="index.index == 8" class="banner-item-sp" style="font-size: 72px">
          {{ globalData.index.txt3 }}
          <span class="banner-item-sp">{{ globalData.index.sp3 }}</span>
        </span>
      </div>
      <!-- {{ globalData.index.txt1
      }}<span class="banner-item-sp">{{ globalData.index.sp1 }}</span>{{ globalData.index.txt2
      }}<span class="banner-item-sp">{{ globalData.index.sp2 }}</span> -->
    </top-nav>
    <div class="about">
      <block-title :title="globalData.index.about.top.title" :en="globalData.index.about.top.en" :sub="globalData.index.about.top.sub"></block-title>
      <div class="tabs content-limit">
        <div
          class="item"
          :class="{ active: index === abCurrentIndex }"
          v-for="(item, index) of globalData.index.about.tabs"
          :key="index"
          @click="abCurrentIndex = index"
        >
          <div class="right">
            <div style="display: flex; align-items: center">
              <img class="img" :src="abtabs[index]" alt="" />
              <div class="title">{{ item.title }}</div>
            </div>
            <div class="brief">{{ item.brief }}</div>
          </div>
        </div>
      </div>
      <template v-for="(item, index) of globalData.index.about.display">
        <div class="display" :key="index" v-if="abCurrentIndex === index">
          <!-- <div class="info">
            <div class="title">{{ item.title }}</div>
            <div class="info-item" v-for="(inneritem, innerindex) of item.infos" :key="innerindex">
              {{ inneritem }}
            </div>
          </div> -->
          <video class="info-img" muted loop autoplay v-if="['.mp4'].includes(infos[index]?.slice(-4))" :src="infos[index]"></video>
          <img v-else class="info-img" :src="infos[index]" alt="" />
        </div>
      </template>
    </div>
    <div
      class="drive"
      :style="{
        background: `url(${
          driveCurrentIndex === 0
            ? drivebg[0]
            : driveCurrentIndex === 1
            ? drivebg[1]
            : driveCurrentIndex === 2
            ? drivebg[2]
            : driveCurrentIndex === 3
            ? drivebg[3]
            : drivebg[4]
        }) no-repeat center/cover`
      }"
    >
      <div class="en-title">{{ globalData.index.drive.enTitle }}</div>
      <div class="ch-title">{{ globalData.index.drive.chTitle }}</div>
      <div class="ch-brief">{{ globalData.index.drive.chBrief }}</div>
      <div class="content content-limit">
        <div class="left">
          <div class="item" v-for="(item, index) of drive" :key="index" @click="driveCurrentIndex = index">
            <img class="item-icon" :src="item.left.activeImg" alt="" v-if="driveCurrentIndex === index" />
            <img class="item-icon" :src="item.left.img" alt="" v-else />
            <div class="item-txt" v-if="driveCurrentIndex === index">{{ item.left.txt }}</div>
            <div class="item-active" v-else>{{ item.left.txt }}</div>
          </div>

          <div class="item-strip" :style="{ top: 26.7 * driveCurrentIndex + '%' }"></div>
        </div>
        <div class="right">
          <template v-for="(item, index) of drive">
            <div class="item" v-if="driveCurrentIndex === index" :key="index">
              <div class="title">{{ item.right.title }}</div>

              <div v-if="index == 0">
                <div class="brief">
                  <div>✓ 一飞百城战略赋能城市核心地标，打造夜空中耀眼的科技宣发平台</div>
                  <div>✓ 作为行业开创者具备先发优势，已迅速覆盖一线及新一线城市，同时拥有200+城市核心地标的空域运营权飞行权</div>
                  <div>✓ 业务覆盖全国，多城联飞同飞，为品牌客户实现“刷屏级”的传播声量与影响力，形成集团的高壁垒与高增速发展</div>
                </div>
              </div>
              <div v-if="index == 1">
                <div class="brief">
                  <div>✓文旅行业正在进入竞争边界模糊而激烈的“泛娱乐”产业，进入一个真正以经济效益为考量的重运营周期</div>
                  <div>✓ 一飞洞察文旅行业，制定2022年重点战略部署——布局与全国5A景区常态化合作，占领“蓝海”夜经济，为大文旅赋能</div>
                  <div>✓ 联动其他优势与资源，提升景区直接与间接收入来源，携手共赢</div>
                  <div>✓ 以文旅产品重塑、产品体系构建、市场流量沉淀以及二消能力提升等全闭环逻辑，多维度全面赋能景区，创新文旅</div>
                </div>
              </div>
              <div class="brief" v-if="index == 2">
                <div>✓除了空域编队表演，无人机集群控制技术、空中物流运输平台、自动巡检系统及定制无人机系统等领先技术，为万余架无人机</div>
                <div>✓协同作业提供智能控制与云平台系统解决方案，为景区提供联防联控服务</div>
              </div>
              <div class="brief" v-if="index == 3">
                ✓
                一飞元宇宙基于低空无人机视角和数字孪生技术，以无人机和通用航空产业为主导，涵盖空域造景、VR共享无人机、低空遥感、空中艺术、联防联控、通航服务、科研教育等众多行业的经济概念，是辐射带动效应强，产业链较长的综合经济形态。
              </div>
              <!-- <div class="more-button" @click="$router.push(item.right.to)">
                {{ item.right.button }}
                <img src="@/asset/index/ic_index_48.png" class="more-icon" alt="" />
              </div> -->
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="advantage">
      <block-title
        :title="globalData.index.advantage.top.title"
        :en="globalData.index.advantage.top.en"
        :sub="globalData.index.advantage.top.sub"
      ></block-title>
      <div class="content content-limit">
        <div class="left">
          <img class="icon" src="../asset/index/ic_index_29.png" alt="" />
          <div class="title">{{ globalData.index.advantage.left.title }}</div>
          <div class="en-title">
            {{ globalData.index.advantage.left.enTitle }}
          </div>
          <div class="brief">
            {{ globalData.index.advantage.left.brief }}
          </div>
        </div>
        <div class="right">
          <div class="item" v-for="(item, index) of advantage" :key="index">
            <img class="icon" :src="item.icon" alt="" />
            <div class="title">{{ item.title }}</div>
            <div class="en-title">{{ item.en }}</div>
            <div class="brief">
              {{ item.brief }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="case">
      <block-title :title="globalData.index.case.top.title" :en="globalData.index.case.top.en" :sub="globalData.index.case.top.sub"></block-title>
      <div class="content-limit">
        <div class="list">
          <div
            v-for="(item, index) of globalData.index.case.list"
            :key="index"
            class="item"
            :style="{
              background: `url(${casebg[index]})  no-repeat center/cover`
            }"
          >
            <div class="img-box">
              <img :src="casebg[index]" />
            </div>

            <div class="hover">
              <!-- <div class="title">{{ item.title }}</div> -->
              <div class="sub">{{ item.sub }}</div>
              <!-- <div class="brief">
                {{ item.brief }}
              </div> -->
            </div>
          </div>
        </div>
        <div class="more-button" @click="$router.push('/product')">
          查看更多
          <img src="../asset/index/ic_index_48.png" class="more-icon" alt="" />
        </div>
      </div>
    </div>
    <div class="confirm">
      <block-title
        :title="globalData.index.confirm.top.title"
        :en="globalData.index.confirm.top.en"
        :sub="globalData.index.confirm.top.sub"
      ></block-title>
      <div style="display: flex; align-items: center; justify-content: center; margin-top: 80px">
        <div style="position: relative" @click="changeVideoStatus1">
          <video
            src="/static-images/tj (1).mp4"
            ref="video1"
            style="width: 627px; height: 418px"
            @play="videoIsPlay1 = true"
            @pause="videoIsPlay1 = false"
            @ended="videoIsPlay1 = false"
            preload="none"
            loop
          ></video>
          <div class="msks" :style="{ background: b1 }"></div>
          <div class="video-img" v-show="!videoIsPlay1">
            <img src="../asset/index/ic_index_78.png" />
          </div>
          <div class="video-title">天津方特</div>
        </div>
        <div style="position: relative; margin-left: 26px" @click="changeVideoStatus2">
          <video
            src="/static-images/tj (2).mp4"
            ref="video2"
            style="width: 627px; height: 418px"
            @play="videoIsPlay2 = true"
            @pause="videoIsPlay2 = false"
            @ended="videoIsPlay2 = false"
            preload="none"
            loop
          ></video>
          <div class="msks" :style="{ background: b2 }"></div>
          <div class="video-img" v-show="!videoIsPlay2">
            <img src="../asset/index/ic_index_78.png" />
          </div>
          <div class="video-title">天塔达沃斯</div>
        </div>
      </div>
      <div style="display: flex; align-items: center; justify-content: center; margin-top: 26px">
        <div style="position: relative" @click="changeVideoStatus3">
          <video
            src="/static-images/tj (3).mp4"
            ref="video3"
            style="width: 627px; height: 418px"
            @play="videoIsPlay3 = true"
            @pause="videoIsPlay3 = false"
            @ended="videoIsPlay3 = false"
            preload="none"
            loop
          ></video>
          <div class="msks" :style="{ background: b3 }"></div>
          <div class="video-img" v-show="!videoIsPlay3">
            <img src="../asset/index/ic_index_78.png" />
          </div>
          <div class="video-title">航母公园</div>
        </div>
        <div style="position: relative; margin-left: 26px" @click="changeVideoStatus4">
          <video
            src="/static-images/tj (4).mp4"
            ref="video4"
            style="width: 627px; height: 418px"
            @play="videoIsPlay4 = true"
            @pause="videoIsPlay4 = false"
            @ended="videoIsPlay4 = false"
            preload="none"
            loop
          ></video>
          <div class="msks" :style="{ background: b4 }"></div>
          <div class="video-img" v-show="!videoIsPlay4">
            <img src="../asset/index/ic_index_78.png" />
          </div>
          <div class="video-title">天津河西文化中心西岸文化消费季</div>
        </div>
      </div>
      <div class="more-button" @click="$router.push('/cooperation-case')">
        查看更多
        <img src="../asset/index/ic_index_48.png" class="more-icon" alt="" />
      </div>
    </div>

    <customer-wall></customer-wall>
    <my-footer></my-footer>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
// import CustomerWall from '../components/view-index/customer-wall.vue';
import bg1 from '../asset/index/ic_index_15n.png';
import bg2 from '../asset/index/ic_index_08.png';
import bg3 from '../asset/index/ic_index_09.png';
import bg4 from '../asset/index/ic_index_10.png';
import bg5 from '../asset/index/ic_index_11.png';
import bg6 from '../asset/index/ic_index_12.png';

import abimg1 from '../asset/index/ic_index_46.png';
import abimg2 from '../asset/index/ic_index_44.png';
import abimg3 from '../asset/index/ic_index_43.png';
import abimg4 from '../asset/index/ic_index_45.png';

import infoimg1 from '../asset/index/ic_index_77.png';
import infoimg2 from '../asset/index/ic_index_74.png';
import infoimg3 from '../asset/index/ic_index_75.png';
import infoimg4 from '../asset/index/ic_index_76.png';

import drivebg1 from '../asset/index/ic_index_54.png';
import drivebg2 from '../asset/index/ic_index_54.png';
import drivebg3 from '../asset/index/ic_index_54.png';
import drivebg4 from '../asset/index/ic_index_54.png';
// import drivebg2 from "../asset/index/ic_index_55.png";
// import drivebg3 from "../asset/index/ic_index_56.png";
// import drivebg4 from "../asset/index/ic_index_57.png";
// import drivebg5 from "../asset/index/ic_index_58.png";

import casebg1 from '../asset/index/ic_index_17.png';
import casebg2 from '../asset/index/ic_index_18.png';
import casebg3 from '../asset/index/ic_index_19.png';
import casebg4 from '../asset/index/ic_index_20.png';

import swiperbg1 from '../asset/index/ic_index_32.png';
import swiperbg2 from '../asset/index/ic_index_64.png';
import swiperbg3 from '../asset/index/ic_index_65.png';
import swiperbg4 from '../asset/index/ic_index_66.png';
import swiperbg5 from '../asset/index/ic_index_67.png';

let plans = [];

export default {
  data() {
    return {
      b1: '',
      b2: '',
      b3: '',
      b4: '',
      videoIsPlay1: false,
      videoIsPlay2: false,
      videoIsPlay3: false,
      videoIsPlay4: false,
      banner: [
        '/static-images/index-v1.mp4',
        '/static-images/index-v2.mp4',
        '/static-images/index-v3.mp4',
        // '/static-images/index-v4.mp4',
        '/static-images/index-v5.mp4',
        '/static-images/index-v6.mp4',
        bg1,
        bg2,
        bg3
      ],
      abtabs: [abimg1, abimg2, abimg3, abimg4],
      infos: ['/static-images/index-d1.mp4', infoimg2, infoimg3, infoimg4],
      drivebg: [drivebg1, drivebg2, drivebg3, drivebg4],
      casebg: [casebg1, casebg2, casebg3, casebg4],
      applyCurrentIndex: 0,
      abCurrentIndex: 0,
      driveCurrentIndex: 0,
      swiperCurrentIndex: 0
    };
  },
  methods: {
    changeVideoStatus1() {
      const video = this.$refs.video1;
      if (video.paused) {
        video.play();
        this.b1 = 'transparent';
      } else {
        video.pause();
        this.b1 = '#333';
      }
    },
    changeVideoStatus2() {
      const video = this.$refs.video2;
      if (video.paused) {
        video.play();
        this.b2 = 'transparent';
      } else {
        video.pause();
        this.b2 = '#333';
      }
    },
    changeVideoStatus3() {
      const video = this.$refs.video3;
      if (video.paused) {
        video.play();
        this.b3 = 'transparent';
      } else {
        video.pause();
        this.b3 = '#333';
      }
    },
    changeVideoStatus4() {
      const video = this.$refs.video4;
      if (video.paused) {
        video.play();
        this.b4 = 'transparent';
      } else {
        video.pause();
        this.b4 = '#333333';
      }
    },

    next() {
      if (this.swiperCurrentIndex < this.swiper?.length - 1) {
        this.swiperCurrentIndex++;
      } else {
        this.swiperCurrentIndex = 0;
      }
    },
    sendInfo() {
      this.$router.push({
        name: 'get-plan',
        params: {
          name: this.$refs.name.value,
          mobile: this.$refs.phone.value,
          plan: plans[this.applyCurrentIndex]
        }
      });
    }
  },
  computed: {
    ...mapGetters(['globalData']),
    drive() {
      return window.deepMerge(
        [
          {
            left: {
              img: require('@/asset/index/ic_index_59.png'),
              activeImg: require('@/asset/index/ic_index_41.png')
            },
            right: {}
          },
          {
            left: {
              img: require('@/asset/index/ic_index_25.png'),
              activeImg: require('@/asset/index/ic_index_60.png')
            },
            right: {}
          },
          {
            left: {
              img: require('@/asset/index/ic_index_40.png'),
              activeImg: require('@/asset/index/ic_index_61.png')
            },
            right: {}
          },
          {
            left: {
              img: require('@/asset/index/ic_index_39.png'),
              activeImg: require('@/asset/index/ic_index_62.png')
            },
            right: {}
          }
        ],
        this.globalData.index.drive.list
      );
    },
    advantage() {
      return window.deepMerge(
        [
          { icon: require('@/asset/index/ic_index_28.png') },
          { icon: require('@/asset/index/ic_index_27.png') },
          { icon: require('@/asset/index/ic_index_47.png') },
          { icon: require('@/asset/index/ic_index_26.png') }
        ],
        this.globalData.index.advantage.right
      );
    },
    swiper() {
      return window.deepMerge(
        [
          {
            img: swiperbg1,
            en: 'Yunnan Yunfeng Expressway',
            title: '云南云凤高速',
            brief:
              '“贵司提供的智慧城市安全监测软件解决方案在提高城市安全方面发挥了重 要作用。我们非常满意贵司的专业技术和优质服务，感谢你们的团队在项目实施过程中所做出的努力和贡献。”'
          },
          {
            img: swiperbg2,
            en: 'Monitoring of Xiaoshiba collapse',
            title: '社区小石坝崩塌监测',
            brief:
              '“我们与贵公司合作的项目在智慧城市安全监测领域取得了显著成效。该软件不仅提高了城市的安全性，还为市民提供了更加便捷的生活服务。我们对贵司的专业能力和执行效率给予高度评价，期待未来有更多的合作机会。”'
          },
          {
            img: swiperbg3,
            en: 'Kunming Monitoring of mining areas',
            title: '昆明经开区倪家营小石牛采矿区监测',
            brief: '“有效地提升了我们城市的应急响应速度和安全防范能力。感谢团队的专业服务和支持，希望未来能够继续保持紧密的合作关系。”'
          },
          {
            img: swiperbg4,
            en: 'Monitoring of Nengyuan Quarry',
            title: '昆明经开区能原采石场监测',
            brief:
              '“我们深刻感受到了贵司的专业水准和服务态度。智慧城市安全监测软件不仅满足了我们的需求，还为城市的可持续发展提供了有力支持。我们再次感谢贵司为我们提供的优质产品和服务。”'
          },
          {
            img: swiperbg5,
            en: 'Landslide monitoring in Sanwa Village',
            title: '高坡社区三瓦村滑坡监测',
            brief:
              '“该系统不仅具有高度的技术先进性和实用性，而且操作简便，为我们的管理工作带来了极大的便利。我们高度赞赏贵司的专业能力、技术实力和优质服务，期待未来有更多的合作机会。”'
          }
        ],
        this.globalData.index.confirm.swiper
      );
    },
    apply() {
      return window.deepMerge(
        [
          {
            icon: require('@/asset/index/ic_index_37.png'),
            hicon: require('@/asset/index/ic_index_69.png')
          },
          {
            icon: require('@/asset/index/ic_index_24.png'),
            hicon: require('@/asset/index/ic_index_73.png')
          },
          {
            icon: require('@/asset/index/ic_index_70.png'),
            hicon: require('@/asset/index/ic_index_36.png')
          },
          {
            icon: require('@/asset/index/ic_index_35.png'),
            hicon: require('@/asset/index/ic_index_71.png')
          },
          {
            icon: require('@/asset/index/ic_index_34.png'),
            hicon: require('@/asset/index/ic_index_72.png')
          }
        ],
        this.globalData.index.apply.tabs
      );
    }
  },
  mounted() {
    console.log(this);
    plans = this.globalData.index.apply.tabs.map(v => {
      return v.txt;
    });
  },
  destroyed() {}
};
</script>

<style scoped lang="scss">
video {
  object-fit: cover;
  display: block;
}

.msks {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #333;
  opacity: 0.4;
}

.about {
  padding: 80px 0;

  .tabs {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 40px;

    .item {
      width: 302px;
      // height: 320px;
      flex: 1;
      display: flex;

      padding: 40px;
      background: #fff !important;
      position: relative;
      cursor: pointer;
      gap: 16px;

      .img {
        width: 24px;
        object-fit: contain;
        flex-shrink: 0;
      }

      .right {
        display: flex;
        flex-direction: column;
        // justify-content: space-between;

        .title {
          font-size: 24px;
          color: #333333;
          margin-left: 8px;
        }

        .brief {
          font-size: 16px;
          color: #666666;
          margin-top: 16px;
          line-height: 24px;
        }
      }
    }

    .active {
      box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);

      &::before {
        content: '';
        position: absolute;
        width: 18px;
        height: 18px;
        top: 100%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
        background: white;
      }

      &::after {
        content: '';
        position: absolute;
        width: 28px;
        height: 28px;
        bottom: 0;
        left: 50%;
        background: white;
        transform: translateX(-50%);
      }
    }
  }
}

.display {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: 50px;

  .info {
    width: 500px;
    height: 220px;
    margin-top: 60px;
    padding: 60px;
    padding-right: 30px;
    border-left: 10px solid #dddddd;
    border-bottom: 10px solid #dddddd;

    .title {
      font-size: 24px;
      color: #4e99fd;
      margin-bottom: 30px;
    }

    .info-item {
      position: relative;
      font-size: 18px;
      color: #666666;
      line-height: 2;
      padding-left: 20px;

      &::after {
        content: '';
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: #4e99fd;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .info-img {
    width: 1280px;
    height: 445px;
  }
}

.drive {
  line-height: 1;
  padding: 80px 0;
  transition: 0.5s all;

  // background: #333333;
  // opacity: 0.6;
  .en-title {
    font-size: 36px;
    color: rgba(255, 255, 255, 0.4);
    margin-bottom: 10px;
    text-align: center;
  }

  .ch-title {
    font-size: 36px;
    color: white;
    margin-bottom: 34px;
    text-align: center;
    font-family: YouSheBiaoTiHei;
  }

  .ch-brief {
    font-size: 20px;
    color: rgba(255, 255, 255, 0.6);
    text-align: center;
    margin-bottom: 80px;
  }

  .content {
    display: flex;
    height: 420px;

    .left {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      border-right: 2px solid rgba($color: white, $alpha: 0.6);
      flex-shrink: 0;

      .item {
        display: flex;
        align-items: center;
        gap: 8px;
        padding-right: 40px;
        cursor: pointer;

        .item-icon {
          width: 20px;
        }

        .item-txt {
          font-size: 20px;
          font-weight: bold;
          color: white;
        }

        .item-active {
          font-size: 20px;
          // font-weight: none;
          color: #fff;
          opacity: 0.8;
        }
      }

      .item-strip {
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        width: 4px;
        height: 20%;
        transition: all 0.5s;
        margin-right: -1px;
        background: #4e99fd;
      }
    }

    .right {
      padding-left: 80px;

      .item {
        .title {
          font-size: 36px;
          color: white;
        }

        .brief {
          font-size: 20px;
          line-height: 2;
          margin: 40px 0;
          color: rgba($color: white, $alpha: 0.6);
        }
      }
    }
  }
}

.more-button {
  width: 217px;
  height: 61px;
  margin: 0 auto;
  background: #4e99fd;
  border-radius: 2px 2px 2px 2px;
  font-size: 24px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin-top: 60px;
  cursor: pointer;

  .more-icon {
    width: 30px;
  }
}

.advantage {
  padding: 80px 0;

  .content {
    display: flex;
    justify-content: center;
    gap: 24px;
    margin-top: 60px;

    .left {
      color: white;
      background: url(../asset/index/ic_index_21.png) no-repeat center/cover;
      padding: 70px 40px;
      width: 427px;
      height: 667px;
      box-sizing: border-box;
      flex-shrink: 0;

      .icon {
        width: 60px;
        margin-bottom: 40px;
        // border: 1px dashed #666;
      }

      .title {
        font-size: 28px;
        margin-bottom: 8px;
      }

      .en-title {
        font-size: 12px;
        margin-bottom: 40px;
        color: rgba(255, 255, 255, 0.8);
      }

      .brief {
        font-size: 16px;
        line-height: 2;
        color: rgba(255, 255, 255, 0.8);
      }
    }

    .right {
      display: flex;
      flex-wrap: wrap;
      width: 690px;
      gap: 24px;

      .item {
        padding: 40px;
        width: 328px;
        height: 320px;
        box-sizing: border-box;
        border: 1px solid #4e99fd;
        border-radius: 4px;

        .icon {
          height: 60px;
          margin-bottom: 24px;
        }

        .title {
          font-size: 28px;
          margin-bottom: 8px;
        }

        .en-title {
          font-size: 12px;
          margin-bottom: 24px;
          color: rgba(153, 153, 153, 0.8);
        }

        .brief {
          font-size: 16px;
          line-height: 2;
          color: #666666;
        }
      }
    }
  }
}

.case {
  padding: 80px 0;
  background: url(../asset/index/ic_index_68.png) no-repeat center/cover;

  .list {
    display: flex;
    justify-content: space-between;
    margin-top: 80px;
    margin-bottom: 24px;

    .img-box {
      background: #ffffff;
      width: 213px;
      height: 193px;
      margin: 0 auto;
      overflow: hidden; //img如果超出这个div会隐藏超出部分
      display: flex; //flex布局
      align-items: center; //让img放在div的中间，居中
      margin-top: 42px;

      img {
        width: 100%;
      }
    }

    .item {
      width: 302px;
      height: 300px;
      position: relative;
      border-radius: 8px !important;
      background: #ffffff !important;

      // background-image: url('../asset/index/ic_index_17.png');
      .hover {
        // padding: 24px 40px;

        background: rgba($color: white, $alpha: 0.8);
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        transition: all 0.5s;
        box-sizing: border-box;
        // height: 104px;
        box-sizing: border-box;
        overflow: hidden;
        text-align: center;

        .title {
          color: #333333;
          font-size: 24px;
          margin-bottom: 16px;
        }

        .sub {
          color: #666666;
          font-size: 14px;
          padding-bottom: 21px;
        }

        .brief {
          color: white;
          font-size: 16px;
          line-height: 2;
          margin-top: 40px;
          transition: 0.5s all;
        }
      }

      &:hover {
        .hover {
          display: flex;
          justify-content: center;
          width: 276px;
          height: 135px;
          background: #4e99fd;
          border-radius: 8px 8px 8px 8px;
          opacity: 0.6;
          line-height: 135px;
          margin-left: 13px;
          margin-bottom: 13px;
        }

        .title,
        .sub {
          color: white;
        }
      }
    }

    .item:hover {
      box-shadow: 5px 8px 20px 0px #c9d4e4;
      border-radius: 8px !important;
      // box-shadow: 5px 8px 20px 0px #DADADA;
    }
  }
}

.confirm {
  padding-top: 96px;

  .video-img {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 44% !important;
    left: 50%;
    margin-left: -20px;
  }

  .video-title {
    position: absolute;
    bottom: 0;
    width: 627px;
    height: 104px;
    background: linear-gradient(134deg, rgba(255, 255, 255, 0.52) 0%, rgba(255, 255, 255, 0.0052) 100%);
    border-radius: 2px 2px 2px 2px;

    font-weight: 500;
    font-size: 20px;
    color: #ffffff;
    text-align: center;
    line-height: 104px;
  }

  .content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1920px;
    margin: 0 auto;
    margin-top: 68px;

    .swiper-list {
      position: relative;
      height: 505px;
      flex-grow: 1;
      overflow: hidden;

      .swiper-item {
        width: 100%;
        height: 100%;
        position: absolute;
        transition: 0.5s all;
        text-align: center;
        display: flex;
        align-items: flex-start;
        gap: 80px;

        .swiper-img {
          width: 960px;
          height: 505px;
        }

        .swiper-right {
          text-align: left;
          padding-right: 190px;

          .swiper-num {
            margin-bottom: 40px;

            .sp {
              font-size: 40px;
              color: #4e99fd;
              font-weight: bold;
            }

            .count {
              font-size: 18px;
              color: #dddddd;
            }
          }

          .swiper-en {
            color: #dddddd;
            font-size: 24px;
            position: relative;
            margin-bottom: 16px;
            display: inline-block;

            &::after {
              content: '';
              position: absolute;
              width: 80px;
              height: 4px;
              left: 100%;
              top: 50%;
              transform: translateY(-50%);
              margin-left: 24px;
              background: #4e99fd;
            }
          }

          .swiper-title {
            font-size: 24px;
            color: #4e99fd;
            margin-bottom: 70px;
            font-weight: bold;
          }

          .swiper-brief {
            font-size: 18px;
            color: #666666;
            line-height: 2;
            margin-bottom: 30px;
            width: 500px;
          }

          .swiper-more {
            font-size: 18px;
            color: #4e99fd;
            gap: 8px;
            display: flex;
            align-items: center;
            cursor: pointer;

            .swiper-icon {
              width: 30px;
            }
          }
        }
      }
    }

    .next {
      flex-shrink: 0;
      position: absolute;
      right: 190px;
      top: 50%;
      transform: translateX(-50%);
      cursor: pointer;
    }

    .indicator {
      position: absolute;
      bottom: 0;
      left: 1040px;
      display: flex;
      align-items: center;
      gap: 24px;

      .indicator-item {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: #4e99fd;
        opacity: 0.2;
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }
      }

      .indicator-item-active {
        opacity: 0.8;
      }
    }
  }
}

.strip {
  margin-top: 120px;
  position: relative;

  .bg {
    width: 100%;
  }

  .content {
    position: absolute;
    box-sizing: border-box;
    top: 27%;
    right: calc(((100% - 1920px) / 2) + 320px);

    .title {
      color: #333333;
      font-size: 36px;
      margin-bottom: 9px;
      font-family: YouSheBiaoTiHei;
      text-align: center;
    }

    .en {
      color: rgba(51, 51, 51, 0.4);
      font-size: 24px;
      margin-bottom: 80px;
      text-align: center;
    }

    .digital {
      display: flex;
      justify-content: center;
      gap: 140px;

      .item {
        .num {
          font-size: 48px;
          color: #4e99fd;
          font-weight: bold;
        }

        .txt {
          font-size: 18px;
          color: #666666;
          margin-top: 16px;
        }
      }
    }
  }
}

.apply {
  margin: 100px 0;

  .tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 70px;
    margin-bottom: 40px;
    margin-top: 80px;

    .item {
      width: 200px;
      height: 98px;
      display: flex;
      align-items: center;
      gap: 16px;
      justify-content: center;
      background: #ffffff;
      box-shadow: 3px 3px 8px 1px #e8e8e8;
      border-radius: 2px 2px 2px 2px;
      font-size: 24px;
      color: #333333;
      cursor: pointer;

      .icon {
        width: 32px;
      }

      .hover {
        display: none;
      }

      &:hover,
      &.active {
        background: linear-gradient(180deg, #4e99fd 0%, #5482fa 100%);
        color: white;

        .icon {
          display: none;
        }

        .hover {
          display: block;
        }
      }
    }
  }

  .form {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 70px;

    .name,
    .phone {
      font-size: 24px;
      border: 1px solid #dddddd;
      outline: none;
      height: 60px;
      padding: 0 28px;
      width: 470px;
      box-sizing: border-box;
    }

    .button {
      flex-shrink: 0;
      background: linear-gradient(180deg, #4e99fd 0%, #5482fa 100%);
      box-shadow: 3px 3px 8px 1px #e8e8e8;
      border-radius: 2px 2px 2px 2px;
      width: 200px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      font-size: 24px;
      cursor: pointer;
    }
  }
}
</style>
